import { z } from 'zod';
import { countryEnum, technologyEnum } from './enums';
import { moneySchema } from './money';

import type { ChipProps } from '~/components/granular';

export type DealsViewType = 'per-deal' | 'per-delivery-dates';

export const certificateLabelEnum = z.enum([
  'Green-E',
  'TUV-Nord',
  'TUV-EE',
  'TUV-EE+',
  'TUV-EE-01',
  'TUV-EE-02',
  'EKOenergy',
  'OK-power',
  'Naturemade_Star',
]);

export const dealSideEnum = z.enum(['BUY', 'SELL']);

export const dealStatusEnum = z.enum(['OPEN', 'PART_SETTLED', 'SETTLED']);
export const dealTrancheStatusEnum = z.enum(['OPEN', 'PART_SETTLED', 'SETTLED']);
export const dealTrancheVolumeStatusEnum = z.enum(['OPEN', 'SETTLED']);
export type DealStatus = z.infer<typeof dealStatusEnum>;
export type DealTrancheStatus = z.infer<typeof dealTrancheStatusEnum>;
export type DealTrancheVolumeStatus = z.infer<typeof dealTrancheVolumeStatusEnum>;
export const DEAL_TYPE_COLORS: {
  [key in DealStatus]: ChipProps['appearance'];
} = {
  SETTLED: 'success',
  PART_SETTLED: 'warning',
  OPEN: 'info',
} as const;

export const registryTransactionTypeEnum = z.enum(['CANCEL', 'TRANSFER']);

export const supraNationalRegionEnum = z.enum(['AIB', 'AIB Grid Connected']);

export const trancheSchema = z.object({
  id: z.string().uuid(),
  delivery_date: z.string().date(),
  price_per_mwh: moneySchema.nullable(),
  status: dealTrancheStatusEnum,
  volumes: z.array(
    z.object({
      id: z.string().uuid(),
      vintage_start: z.string().date(),
      vintage_end: z.string().date(),
      volume: z.string(),
      status: dealTrancheVolumeStatusEnum,
      created_at: z.string().datetime(),
      updated_at: z.string().datetime(),
    }),
  ),
  created_at: z.string(),
  updated_at: z.string(),
  registry_transaction_type: registryTransactionTypeEnum.nullable(),
  registry_account_id: z.string().uuid().nullable(),
  registry_account_holder_id: z.string().uuid().nullable(),
  requirement_subsidy_regime: z.boolean().nullable(),
  requirement_minimum_commercial_operation_date: z.string().date().nullable(),
  requirement_country: z.array(countryEnum).nullable(),
  requirement_sub_national_region: z.array(z.string()).nullable(),
  requirement_supra_national_region: z.array(supraNationalRegionEnum).nullable(),
  requirement_technology: z.array(technologyEnum).nullable(),
  requirement_certificate_label: z.array(certificateLabelEnum).nullable(),
  requirement_certificate_type: z.string(),
});
export type Tranche = z.infer<typeof trancheSchema>;

export const dealSchema = z.object({
  id: z.string().uuid(),
  created_at: z.string().datetime(),
  updated_at: z.string().datetime(),
  client_reference: z.string().nullable(),
  side: dealSideEnum,
  counterparty_id: z.string().uuid(),
  counterparty_name: z.string(),
  executed_at: z.string().datetime(),
  currency: z.string(),
  status: dealStatusEnum,
  tranches: z.array(trancheSchema),
  total_volume: z.string(),
  average_price_per_mwh: moneySchema.nullable(),
  total_deal_value: moneySchema.nullable(),
});
export type Deal = z.infer<typeof dealSchema>;

export const dealListSchema = z.array(dealSchema);
export type DealList = z.infer<typeof dealListSchema>;

// flatmap schema between deal and tranches: this is what the grid will display
export const dealTrancheSchema = dealSchema
  .omit({ tranches: true })
  .merge(trancheSchema)
  .merge(
    z.object({
      deal_type: z.literal('firm'),
      generation_period_start: z.string().date().nullable(),
      generation_period_end: z.string().date().nullable(),
    }),
  );
export type DealTranche = z.infer<typeof dealTrancheSchema>;

export const deliveryTrancheVolumeSchema = z.object({
  vintage_start: z.string().datetime(),
  vintage_end: z.string().datetime(),
  volume: z.number().int().positive(),
  status: dealTrancheVolumeStatusEnum,
});
export type DeliveryTrancheVolume = z.infer<typeof deliveryTrancheVolumeSchema>;

export const deliveryTrancheSchema = z.object({
  delivery_date: z.string().date(),
  unit_price: z.string().optional(),
  status: dealTrancheStatusEnum,
  tranche_volumes: z.array(deliveryTrancheVolumeSchema),
});
export type DeliveryTranche = z.infer<typeof deliveryTrancheSchema>;

export const deliverableTypes = [
  'requirement_minimum_commercial_operation_date',
  'requirement_subsidy_regime',
  'requirement_country',
  'requirement_technology',
  'requirement_certificate_label',
  'requirement_supra_national_region',
  'requirement_sub_national_region',
  'requirement_certificate_type',
];

export const deliverableValueSchema = z.union([
  z.boolean(),
  z.string(),
  z.number().int().positive(),
  z.string().date(),
  z.array(countryEnum),
  z.array(z.string()),
  z.array(supraNationalRegionEnum),
  z.array(technologyEnum),
  z.array(certificateLabelEnum),
]);
export type DeliverableValueType = z.infer<typeof deliverableValueSchema>;

export const dealFormSchema = z.object({
  currency: z.string(),
  counterparty_id: z.string().uuid(),
  client_reference: z.string(),
  executed_at: z.string().datetime(),
  side: dealSideEnum,
  status: dealStatusEnum,
  registry_transaction_type: registryTransactionTypeEnum,
  // deliverables
  deliverables: z
    .array(
      z.object({
        type: z.string(),
        value: deliverableValueSchema,
      }),
    )
    .optional(),
  // requirements
  requirement_subsidy_regime: z.boolean(),
  requirement_certificate_type: z.string(),
  // tranches
  tranches: z.array(deliveryTrancheSchema),
});
export type DealForm = z.infer<typeof dealFormSchema>;

export const createDealSchema = z.object({
  client_reference: z.string(),
  side: dealSideEnum,
  counterparty_id: z.string().uuid(),
  executed_at: z.string().datetime(),
  currency: z.string(),
  registry_transaction_type: registryTransactionTypeEnum,
  registry_account_id: z.string().uuid().optional(),
  registry_account_holder_id: z.string().uuid().optional(),
  status: dealStatusEnum,
  requirement_subsidy_regime: z.boolean().optional(),
  requirement_minimum_commercial_operation_date: z.string().date().optional(),
  requirement_country: z.array(countryEnum).optional(),
  requirement_sub_national_region: z.array(z.string()).optional(),
  requirement_supra_national_region: z.array(supraNationalRegionEnum).optional(),
  requirement_technology: z.array(technologyEnum).optional(),
  requirement_certificate_label: z.array(certificateLabelEnum).optional(),
  requirement_certificate_type: z.string(),
  tranches: z.array(deliveryTrancheSchema),
});
export type CreateDeal = z.infer<typeof createDealSchema>;

export const dealOptionsSchema = z.object({
  deal_options: z.object({
    currency: z.array(z.string()),
    registry_transaction_type: z.array(z.string()),
    requirement_certificate_type: z.array(z.string()),
    requirement_certificate_label: z.array(z.string()),
    requirement_country: z.array(countryEnum),
    requirement_supra_national_region: z.array(z.string()),
    requirement_technology: z.array(technologyEnum),
    side: z.array(dealSideEnum),
    status: z.array(dealStatusEnum),
  }),
  deal_tranche_options: z.object({
    status: z.array(dealTrancheStatusEnum),
  }),
  deal_tranche_volume_options: z.object({
    status: z.array(dealTrancheVolumeStatusEnum),
  }),
});
export type DealOptions = z.infer<typeof dealOptionsSchema>;

export const commercialOperationDateConstraint = z.enum(['BEFORE', 'ON_OR_AFTER']);
